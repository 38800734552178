/* 
*  OLD SITE SASS
* 
*/ 

@import "./old-site/main"; 


//General
.left {
  padding: 0px;
  padding-right: 25px;
}

.right {
  padding: 0px;
  padding-left: 25px;
}



//Single Customer
.info-box{
  margin-top: 0px;
}

.home-hero h1, .home-hero .h1{
  margin-top: 0px;
}

.home-hero {
  margin-top: 0px !important;
}

.info-box figure{
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box img.customer-logo {
  padding: 25px;
  padding-bottom: 0px;
  max-height: 300px;
  width: auto;
}

.customer-quote {
  border-left: 5px solid #5b2974;
  background-color: #e1d5e1;
  border-radius: 0.25rem;
  margin-top: 40px;
  margin-left: 50px;
  padding: 1rem 25px 1rem 1rem;
}

.text-primary {
  --bs-text-opacity: 1;
  color: #62138e !important;
}

a:hover{
  color: #62138e;
}

.single-resources img {
  border-style: none;
  width: 100%;
  height: auto;
}

.customer-quote p:first-child::before{
    content: " “ ";
    color: rgb(89, 47, 107);
    opacity: 0.3;
    margin-top: -43px;
    margin-left: -35px;
    font-size: 90px;
    position: absolute;
}

.customer-content .container{
  margin: 0 auto;
  max-width: 1240px;
}

.customer-content .row{
  max-width: 100%;
  padding: 0px;
}

//Events
.speaker-flex .rounded-circle {
  max-width: 80px;
  height: auto;
}

//Partners
.left-img.partner-img img{
    width: 100%;
    height: auto;
}

//Resources
img.content{
  max-width: 100%;
}

section.hero h1, section.hero .h1 {
  font-size: 48px;
  font-weight: 700;
}

