.mlops-hero {
  --shape-position: 0;
  padding: 0 0 70px 0;

  ul li {
    list-style: none;
  }

  h2 {
    margin-top: 70px;

    @include screen-below($laptop-sm) {
      margin-top: 52px;
    }

    @include screen-below($mobile) {
      margin-top: 35px;
    }
  }

  h3 {
    margin-bottom: 4px !important;
    font-size: 1.0625rem;

    @include screen-below($mobile) {
      font-size: .6875rem;
    }
  }

  &__grid {
    grid-template-columns: repeat(4, 247px);
    grid-column-gap: 64px;
    justify-content: center;

    @include screen-below($desktop) {
      grid-column-gap: calc(64px * .5);
    }

    @include screen-below($laptop) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: calc(64px * .25);
    }

    @include screen-below($mobile) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 34px;
      margin-top: 30px;
    }
  }

  &__grid-item {
    padding: 28px 15px;
    font-size: .8215rem;
    border-radius: 11px;

    p {
      margin: 0;
      font-size: .8215rem;

      @include screen-below($mobile) {
        font-size: .5rem;
      }
    }

    &:nth-of-type(3) {
      z-index: 2;
    }

    @include screen-below($tablet) {
      padding: 18px;
    }
  }

  &__logo {
    right: var(--container-gutter);
    bottom: 58px;
  }

  &__tusks {
    bottom: 10px;
    left: 0;

    @include screen-below($portrait) {
      bottom: -40px;
    }
  }

  &__shapes-left {
    top: 58px;
    left: var(--shape-position);
  }

  &__shapes-right {
    top: 124px;
    right: var(--shape-position);
  }

  &__train-shape {
    @include size(243px, 200px);
    top: -38px;
    left: 52%;
    transform: translateX(-50%);
  }

  &__bottom-line {
    @include size(940px, 82px);
    bottom: -84px;
    left: 50%;
    transform: translateX(-50%);

    @include screen-below($laptop-sm) {
      @include size(705px, 62px);
      bottom: -62px;
    }
  }

  &__straight-line {
    --position: calc(65px * -1);
    width: 65px;
    top: 64px;

    &--left {
      right: var(--position);
    }

    &--right {
      left: var(--position);
    }
  }

  &__mobile-line {
    &--one,
    &--two,
    &--three {
      bottom: -34px;
    }

    &--four,
    &--five {
      top: 50%;
      right: -22px;
    }

    &--four {
      transform: translateY(-50%) rotate(90deg);
    }

    &--five {
      transform: translateY(-50%) rotate(90deg) scaleY(-1);
    }

    &--one {
      left: 50%;
      transform: translateX(-50%);
    }

    &--two {
      left: 38px;
      transform: scaleY(-1);
    }

    &--three {
      right: 38px;
    }

    @include screen-above($mobile) {
      display: none;
    }
  }

  &__mobile-arrows {
    bottom: -30px;
    left: 50%;
    height: 25px;
    transform: translateX(-50%);

    @include screen-above($mobile) {
      display: none;
    }
  }

  @include screen-below($laptop) {
    --shape-position: -100px;
  }

  @include screen-below($laptop-sm) {
    --shape-position: -150px;
  }

  @include screen-below($tablet) {
    &__shapes-left,
    &__shapes-right {
      display: none;
    }
  }

  @include screen-below($mobile) {
    padding-bottom: 175px;

    &__straight-line,
    &__train-shape,
    &__bottom-line {
      display: none;
    }
  }
}
