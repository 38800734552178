.grid {
  display: grid;

  &--header {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 12px;

    @include screen-below($tablet) {
      grid-row-gap: 24px;
    }
  }

  &--header-ctas {
    grid-template-columns: auto 1fr;
    grid-gap: 38px;

    @include screen-above($tablet) {
      justify-self: end;
    }

    @include screen-below($tablet) {
      grid-column: 1 / -1;
      grid-row-gap: 14px;
      margin: 0 var(--container-gutter);
    }
  }

  &--site-header-buttons {
    //grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: end;

    @include screen-below($laptop-sm) {
      //grid-template-columns: auto auto;
    }

    @include screen-below($mobile) {
      grid-column: span 2;
    }
  }

  &--two-column {
    grid-template-columns: repeat(2, 1fr);
  }

  &--three-column {
    grid-template-columns: repeat(3, 1fr);
  }

  &--four-column {
    grid-template-columns: repeat(4, 1fr);
  }

  &--five-column {
    grid-template-columns: repeat(5, 1fr);
  }

  &--links-logo-grid,
  &--logo-grid {
    @include screen-above($laptop-sm) {
      justify-content: space-between;
    }
  }

  &--links-logo-grid {
    grid-template-columns: repeat(4, auto);

    @include screen-below($portrait) {
      grid-gap: 40px;
    }

    @include screen-below($mobile) {
      grid-template-columns: repeat(2, auto);
      grid-gap: 40px 20px;
    }
  }

  &--logo-grid-3 {
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 50px;

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &--logo-grid-4 {
    grid-template-columns: repeat(4, auto);
    align-items: center;

    @include screen-below($laptop-sm) {
      grid-template-columns: repeat(2, auto);
      grid-row-gap: 50px;
    }
  }

  &--home-hero {
    grid-template-columns: 1fr 1.4fr;
    grid-gap: 130px;
    align-items: center;

    @include screen-below($desktop) {
      grid-template-columns: 1fr 1.25fr;
      grid-gap: 100px;
    }

    @include screen-below($desktop-sm) {
      grid-template-columns: 1fr 1.2fr;
      grid-gap: 75px;
    }

    @include screen-below($laptop) {
      grid-gap: 50px;
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 25px;
    }

    @include screen-below($portrait) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  &--resources {
    grid-gap: 38px;

    @include screen-below($portrait) {
      grid-template-columns: 1fr;
    }
  }

  // &--about-buttons {
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-gap: 20px;
  // }

  &--hero-buttons {
    grid-template-columns: 194px 1fr;
    grid-gap: 20px;

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
    }
  }

  &--testimonials {
    grid-gap: 20px;

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
    }
  }

  &--case-studies {
    grid-gap: 0 135px;

    @include screen-below($desktop) {
      grid-gap: 0 100px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 0 75px;
    }

    @include screen-below($laptop) {
      grid-gap: 0 50px;
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
      grid-gap: 135px 0;
    }
  }

  &--links {
    grid-template-columns: 3fr 1fr;

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
      grid-row-gap: 60px;
    }
  }

  &--options {
    grid-template-columns: 440px repeat(3, 1fr);
    grid-gap: 0 20px;

    @include screen-below($desktop-sm) {
      grid-template-columns: 330px repeat(3, 1fr);
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: 210px repeat(3, 1fr);
    }

    @include screen-below($portrait) {
      grid-template-columns: 150px repeat(3, 1fr);
    }

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &--enterprise {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;

    @include screen-below($tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include screen-below($mobile) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    grid-auto-flow: dense;

    @include screen-below($laptop) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--about-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
    }
  }

  &--social-logos {
    grid-column-gap: 45px;

    @include screen-above($laptop-sm) {
      justify-self: end;
    }

    @include screen-below($laptop-sm) {
      place-self: center;
    }
  }

  &--documentation {
    grid-gap: 40px;

    @include screen-below($laptop-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-below($mobile-sm) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &--resources-case-studies {
    @include screen-below($laptop-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &--resources-blog {
    grid-gap: 60px 140px;

    @include screen-below($desktop) {
      grid-gap: 60px 70px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 60px 35px;
    }

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &--resources-blog-article {
    grid-template-columns: 240px 1fr;
    grid-gap: 40px;
    align-items: start;

    @include screen-below($desktop) {
      grid-template-columns: 1fr 2fr;
      grid-gap: 20px;
    }

    @include screen-below($desktop-sm) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &--resources-cards {
    grid-gap: 40px;

    @include screen-below($laptop-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-below($portrait) {
      grid-template-columns: 1fr;
    }
  }

  &--resources-featured-post {
    grid-column-gap: 80px;
    align-items: center;

    @include screen-below($desktop-sm) {
      grid-column-gap: 60px;
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
    }
  }

  &--company-hero {
    grid-template-columns: 580px 1fr;
    grid-gap: 60px;
    align-items: center;

    @include screen-below($desktop-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }

  &--company-about {
    grid-gap: 20px 150px;

    @include screen-below($desktop-sm) {
      grid-gap: 20px 113px;
    }

    @include screen-below($laptop-sm) {
      grid-gap: 20px 75px;
    }

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--careers {
    grid-auto-flow: dense;
    grid-gap: 40px;

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }

    @include screen-below($mobile) {
      grid-gap: 20px;
    }
  }

  &--use-cases {
    grid-template-columns: auto 1fr;
    grid-gap: 60px;

    @include screen-below($desktop) {
      grid-gap: 30px;
    }

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  &--use-cases-cards {
    grid-gap: 42px 22px;
    align-items: stretch;

    @include screen-below($desktop-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  &--post {
    grid-template-columns: 1fr;
    grid-row-gap: 72px;
  }

  &--single-case-study {
    grid-gap: 20px;
    align-items: center;
  }

  &--platform-local {
    grid-template-columns: 854px 1fr;
    grid-column-gap: 170px;

    @include screen-below($desktop) {
      grid-template-columns: 640px 1fr;
      grid-column-gap: 128px;
    }
  }

  &--platform-hub {
    grid-template-columns: 1fr 610px;
    grid-column-gap: 180px;

    @include screen-below($desktop) {
      grid-column-gap: 135px;
    }
  }

  &--platform-local,
  &--platform-hub {
    align-items: center;

    @include screen-below($desktop-sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 90px;
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
      grid-row-gap: 90px;
    }

    @include screen-below($mobile) {
      grid-row-gap: 45px;
    }
  }

  &--concepts {
    grid-gap: 168px;

    @include screen-below($desktop) {
      grid-gap: 126px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 84px;
    }

    @include screen-below($laptop) {
      grid-gap: 42px;
    }
  }

  &--where {
    grid-gap: 40px;

    @include screen-below($tablet) {
      grid-row-gap: 120px;
    }
  }

  &--getting-started {
    @include screen-below($tablet) {
      grid-template-columns: 1fr;
      max-width: 600px;
      margin: 40px auto 80px;
    }
  }

  &--biotech {
    grid-template-columns: 600px 1fr;
    grid-gap: 150px;

    @include screen-below($desktop-sm) {
      grid-gap: 75px;
    }

    @include screen-below($laptop-sm) {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }

    @include screen-below($mobile) {
      grid-gap: 25px;
    }
  }

  &--data-lineage-about {
    grid-auto-flow: dense;
    grid-gap: 30px 142px;
    align-items: start;

    @include screen-below($desktop) {
      grid-gap: 30px 106px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 30px 70px;
    }

    @include screen-below($laptop-sm) {
      grid-gap: 20px;
      align-items: stretch;
    }
  }

  &--data-lineage-gap {
    grid-gap: 170px;

    @include screen-below($desktop) {
      grid-gap: 128px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 85px;
    }

    @include screen-below($laptop-sm) {
      grid-gap: 64px;
    }

    @include screen-below($tablet) {
      grid-gap: 42px;
    }

    @include screen-below($mobile) {
      grid-gap: 20px;
    }
  }

  &--article-with-graphic {
    grid-template-columns: 460px 1fr;
    justify-items: center;
  }

  &--blog-page {
    grid-gap: 40px;

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--related {
    grid-gap: 40px;
  }

  &--author {
    grid-template-columns: 90px 1fr;
    grid-gap: 140px;
    align-items: center;

    @include screen-below($tablet) {
      grid-gap: 70px;
    }

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
      grid-gap: 35px;
    }
  }

  &--footer {
    grid-template-columns: 1fr auto auto auto;

    @include screen-above($desktop) {
      padding-right: 40px;
    }

    @include screen-below($portrait) {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
  }

  &--grid-gap {
    grid-gap: 20px;
  }

  &--biotech-hero {
    grid-template-areas: "header form" "list form";
    grid-gap: 0 100px;
    align-items: start;

    @include screen-above($laptop) {
      align-items: center;
    }

    @include screen-below($tablet) {
      grid-template-areas: "header" "form" "list";
      grid-template-columns: 1fr;
    }
  }

  &--icon-blocks,
  &--studies,
  &--biotech-buttons {
    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--studies {
    @include screen-below($tablet) {
      grid-gap: 100px 0;
    }
  }

  &--404 {
    align-items: end;
    grid-gap: 60px;

    @include screen-below($mobile) {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }

  &--dl-hero {
    grid-template-columns: 560px 1fr;
    grid-gap: 100px;
    align-items: center;

    @include screen-below($desktop) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
    }

    @include screen-below($laptop-sm) {
      align-items: start;
    }
  }

  &--dl-two-col {
    grid-template-columns: 1fr 670px;
    grid-gap: 170px;

    @include screen-below($desktop) {
      grid-gap: 84px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 48px;
    }

    @include screen-below($tablet) {
      grid-gap: 0;
    }
  }

  &--data-lineage-col {
    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--promo {
    grid-template-columns: 540px 1fr;
    grid-gap: 180px;
    align-items: start;

    @include screen-below($desktop) {
      grid-gap: 135px;
    }

    @include screen-below($desktop-sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }

    @include screen-below($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &--types {
    grid-gap: 100px;

    @include screen-below($desktop-sm) {
      grid-gap: 75px;
    }

    @include screen-below($desktop-sm) {
      grid-gap: 50px;
    }

    @include screen-above($laptop-sm) {
      grid-template-columns: 1fr 1.8fr;
    }
  }
}
