.mlops-stack {
  z-index: 1;
  background-color: var(--gray-column);

  p:last-of-type { margin-bottom: 0; }

  &__mobile-link {
    display: inline-block;
    margin-top: 28px;

    @include screen-above($mobile) {
      display: none;
    }
  }

  @include screen-below($mobile) {
    padding-bottom: 114px;
  }
}

.mlops-nav {
  display: flex;
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
  border-bottom: 1px solid #bac7ca;
  padding: 60px 0 30px;
  font-weight: bold;

  &::after {
    content: "";
    display: block;
    position: sticky;
    right: -1px;
    top: 0;
    height: 54px;
    min-width: 61px;
    background: linear-gradient(to right, #ecf5f700, var(--gray-column));
    pointer-events: none;
  }
}

.option {
  display: inline-block;
  padding: 10px;
  margin: 4px;
  cursor: pointer;
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  &:last-child {
    padding-right: 60px;
  }

  .check {
    display: inline-block;
    min-width: 1.8rem;
    min-height: 1.8rem;
    border-radius: 9px;
    border: 2px solid var(--black);
    background: var(--white);
    margin-right: 10px;
    pointer-events: none;
  }

  &.active {
    color: var(--black);

    .check {
      background: currentColor;
      display: grid;
      place-content: center;

      &::before {
        content: "";
        color: var(--black);
        position: relative;
        height: 6px;
        width: 12px;
        transform: rotate(-45deg);
        transform-origin: 5px 2px;
        border-left: 2px solid;
        border-bottom: 2px solid;
      }
    }
  }
}

.mlops-wrapper {
  padding: 20px;
}
