
// sass-lint:disable class-name-format

.partners-fieldset,
.hs-form, 
.mktoForm {
  display: grid;
  grid-gap: 5px; // Reduce this gap from 10px for LP forms

  .radio-field-group {
    margin: 10px 0 15px;
    text-align: left;

    legend {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

.hs-form, 
.mktoForm {
  width: auto !important;
  
  max-width: 80ch;
  margin: auto;

  .mktoLabel .mktoAsterix {
    display: none !important;
  }

  .mktoError {
    right: 0px !important; 
    bottom: -35px !important;

    .mktoErrorArrowWrap {
      width: 16px !important;
    }

    .mktoErrorMsg {
      font-size: 0.85em !important;
    }
  }

  div,
  .mktoFormRow,
  .mktoField {
    width: 100% !important;
  }

  .mktoButtonRow  {
    width: 100% !important;
    text-align: center !important;
  }

  fieldset {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    max-width: 100% !important;

    &.form-columns-1 .hs-input {
      width: 100% !important;
    }
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 18px 12px !important;
    color: var(--black);
    background-color: var(--white);
    font-size: .875rem;
    font-family: 'Montserrat', sans-serif !important;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    opacity: 1;
    box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, .05);

    &::placeholder {
      font-size: .875rem;
      color: var(--black);
      opacity: 1;
    }

    @include screen-below($mobile) {
      padding: 8px;
    }
  }

  input:not([type="checkbox"]),
  select {
    -webkit-appearance: none;
    appearance: none;
  }

  input,
  textarea {
    font-size: 1rem;
  }

  button {
    font-size: 0.85rem;
    width: 100%;
    text-align: center !important;
  }

  select {
    padding: 16px 14px !important;
  }

  .hs-error-msg {
    display: block;
    margin-top: 8px;
    color: var(--red);
    line-height: 1.2;
    font-size: .75rem;
    font-style: italic;
  }

  
  .hs_what_s_your_area_of_expertise {
    position: relative;
    grid-column: 1 / -1;

    ::before {
      position: absolute;
      top: calc(50% - 3px);
      right: 25px;
      padding: 4px;
      border: solid var(--purple);
      border-width: 0 3px 3px 0;
      pointer-events: none;
      transform: translateY(-50%) rotate(45deg);
      content: "";
    }
  }

  .actions {
    text-align: center;
  }

  .hs-form-required {
    color: var(--red);
  }

  .hs-button,
  .mktoButton {
    padding: 13px 20px !important;
    color: var(--white) !important;
    background-color: var(--purple) !important;
    background-image: none !important;
    border-radius: 4px !important;
    border: 0 !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    appearance: none !important;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out, border 300ms ease-in-out !important;

    @include hover {
      color: var(--white) !important;
      background-color: var(--light-purple) !important;
    }

    @include screen-above($mobile) {
      max-width: 270px !important;
    }
  }

  .hs-recaptcha {
    position: absolute;
    top: 0;
    left: -999px;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
}

.legal-consent-container {
  margin-top: 20px;

  .hs-form-booleancheckbox-display {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    align-items: start;

    input {
      width: 20px;
      height: 20px;
      margin: 0;
    }

    > span {
      margin-left: 0 !important;
      font-size: .8125rem;
      font-weight: normal;

      @include screen-above($portrait) {
        justify-self: end;
      }
    }
  }
}

.request-a-demo-form {
  grid-gap: 100px;
  margin-top: 50px;

  .hbspt-form, 
  .mktoForm {
    order: 2;

    @include screen-below($mobile) {
      order: 3;
    }
  }

  .hs-form, 
  .mktoForm {
    grid-template-columns: repeat(2, 1fr);

    .hs-company,
    .hs-demo_request_additional_info,
    .hs-submit,
    .mktoButton {
      grid-column: 1 / -1;
    }
  }

  &__or {
    position: absolute;
    top: 0;
    left: calc(50% - 50px);
    width: 1px;
    height: 100%;
    background-color: var(--border-color);
    transform: translateX(-50%);

    @include screen-below($laptop-sm) {
      left: calc(50% - 25px);
    }

    @include screen-below($tablet) {
      order: 2;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: unset;
    }
  }

  &__or-text {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 20px;
    color: var(--white);
    background-color: var(--light-blue);
    font-weight: 600;
    border-radius: 50%;
    line-height: 1;
    transform: translate(-50%, -50%);

    @include screen-below($tablet) {
      padding: 10px;
    }

    @include screen-below($mobile) {
      font-size: .875rem;
    }
  }

  &__cta {
    order: 1;
  }

  @include screen-below($tablet) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 30px;
  }

  @include screen-below($mobile) {
    grid-gap: 34px;
    margin-top: 15px;
  }
}

.radio-item {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

input[type="checkbox"] {
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:focus + label::before {
    box-shadow: 0 0 6px var(--purple);
  }

  + label {
    position: relative;
    cursor: pointer;
    display: block;
    padding-left: 40px;
    line-height: 1.5;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
    }

    &::before {
      background-color: transparent;
      border: 1px solid var(--purple);
      border-radius: 4px;
      transition: background-color .3s ease;
    }

    &::after {
      background: url("../../../../wp-content/themes/pachyderm/img/graphics/checkmark.svg");
      background-position: center;
      background-repeat: no-repeat;
      transition: opacity .3s ease;
      z-index: 1;
      transform: scale(0);
      opacity: 0;
    }
  }

  &:checked + label {
    &::before {
      background-color: var(--purple);
    }

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.form-error {
  display: block;
  margin-top: 5px;
  color: var(--red);
  font-size: .875rem;
  text-align: left;
}

.form-success {
  p {
    max-width: 640px;
    margin: 6px auto auto;
  }

  &__check {
    max-width: 70px;
    margin: auto;
  }

  &__title {
    display: block;
    margin-top: 4px;
    color: var(--black);
    font-weight: 700;
    font-size: 1.875rem;

    @include screen-below($laptop-sm) {
      font-size: 1.4rem;
    }

    @include screen-below($mobile) {
      font-size: 1rem;
    }
  }

  &__elephant {
    margin: 40px 0 -510px;
    transform: translateX(90px) scaleX(-1);

    @include screen-below($mobile) {
      margin-bottom: -250px;
      transform: translateX(45px) scaleX(-1);
    }
  }
}

.custom-select {
  position: relative;

  div {
    --size: 3px;
    position: absolute;
    top: calc(50% - var(--size));
    right: 25px;
    padding: var(--size);
    border: solid var(--purple);
    border-width: 0 var(--size) var(--size) 0;
    pointer-events: none;
    transform: rotate(45deg) translateY(-50%);
  }
}
// sass-lint:enable class-name-format