/* ============ ADD HERE YOUR CUSTOM SCSS or  plain CSS CODE. ============ 

HOW DOES IT WORK?

    Edit this file, save, 
    view page as admin:
    The style will be AUTOMATICALLY recompiled and 
    your new CSS bundle will be reloaded via ajax to show 
    the new styling edits.

NOTICE for beginners: 

    CODE HERE YOUR MASTERPIECE, but before that, if you haven't yet,
    please take your time to understand and study how Bootstrap works.
    It can save you a lot of time,
    eg. to standardize project colors, or fully customize typography.
    First of all, have a ride in the WordPress Customizer.
    Then learn to use Bootstrap 5 Classes and utilities.
    If you come from the CSS world, take some time to study the basic of SASS. It's very powerful.
    With great powers...you are warned

HOW TO REFERENCE ASSETS AND IMAGES 
    [BREAKING NEWS] The generated CSS bundle is now called from the Theme folder 
    so relative paths work as expected on a standard WordPress Theme.
    So it's not like before.

  
************************************************		*/



:root {
  --container: 1420px;
  --container-gutter: calc((100vw - var(--container)) / 2);
  --dl-container: 1342px;
  --white: #fff;
  --black: #020408;
  --light-gray: #f2e9e9;
  --medium-gray: #b0a8b3;
  --hover-gray: #f6f6f6;
  --gray: #a498a6;
  --background-blue: #ddecee;
  --light-blue: #5ba3b1;
  --dark-blue: #006174;
  --purple: #582f6b;
  --light-purple: #672373;
  --red: #ce6767;
  --salmon: #fe9b7c;
  --body: #3e3840;
  --border-color: #ede9ee;
  --row-border-color: #e5e5e5;
  --alt-border-color: #e1d6db;
  --footer-nav-spacing: 150px;
  --shadow: rgba(0, 0, 0, .11);
  --gray-column: #ecf5f7;
  --code-gray: #e9dddd;
  --step-line: #e7cdcd;
  --step-top-offset: 85px;
  --step-circle-size: 80px;
  --cube-size: 70px;
  --side-offset: 35px;
  --section-padding: 90px;
  --drop-shadow: rgba(226, 239, 241, .8);
  --code: #272822;
}

$desktop: 1680px;
$desktop-sm: 1440px;
$laptop: 1380px;
$laptop-sm: 1240px;
$tablet: 1080px;
$portrait: 880px;
$mobile: 767px;
$mobile-md: 641px;
$mobile-sm: 580px;
$mobile-x-sm: 470px;

.relative {
  position: relative;
}

.positive-z-index {
  z-index: 1;
}


.white {
  background-color: var(--white);
}

.center {
  text-align: center;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin screen-above($screen-size) {
  @media screen and (min-width: $screen-size) {
    @content;
  }
}

@mixin screen-below($screen-size) {
  @media screen and (max-width: ($screen-size - 1px)) {
    @content;
  }
}

@mixin screen-between($min-screen-size, $max-screen-size) {
  @media screen and (min-width: $min-screen-size) and (max-width: ($max-screen-size - 1px)) {
    @content;
  }
}

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

.dropdown:hover>.dropdown-menu { display: block;}
  
.dropdown-toggle::after {
    display:none;
}

.btn {
    padding: 12px 40px;
    text-align: center;
    font-size: .875rem;
    font-weight: 600;
    position: relative;
	  margin-top: 15px;
}

.btn-white{
  background-color: white;
}

.btn.btn-sm{
  padding: 12px 20px;
  width: 100%;
}

.nav-item {
    padding: 0px 10px;
}

.menu-item {
    font-weight: 600;
    font-size: .875rem;
    color: #000;
}

.menu-item a {
  font-weight: 600;
}

.dropdown-menu {
    padding: 10px 0 10px 0 !important;
    font-size: .875rem;
}

.button-box {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
}

.shape {
    position: absolute;
    z-index: -1;
    pointer-events: none;
}


.positive-z-index {
    z-index: 1;
}

.footer-copyright {
    font-size: .875rem;
}

.footer-menu {
    div {
        font-size: .875rem;
        font-weight: 600;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
        margin-top: 8px;
    }
    
    a {
        text-decoration: none;
        font-size: .8125rem;
        color: #000;
        font-weight: 400;
    }
}

/*AW STYLES*/

h3, .h3 {
  font-size: 1.6rem;
  margin-bottom: 25px;
}

h4, .h4 {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.navbar{
  border-bottom: 1px solid var(--border-color);
}

.navbar-brand{
  margin-right: 30px;
  margin-top: -4px;
}

ul#menu-main-nav{
  margin-top: 2px;
}

main#theme-main{
  padding-top: 60px;
}

.white{
    background-color: white;
}

.btn.demo {
  padding: 7px 15px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  position: relative;
  flex-shrink: 0;
}

.nav-item {
  padding: inherit;
}

.row{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.row>* {
  padding-right: calc(var(--bs-gutter-x) * .25);
  padding-left: calc(var(--bs-gutter-x) * .25);
}

section .container{
  padding-top:60px;
  padding-bottom:60px;
}

.row{
  padding-left: 50px;
  padding-right: 50px;
}

.footer.container{
  padding-top: 50px;
  padding-bottom: 15px;
}

section.hero h1{
  font-size: 45px;
  font-weight: 800;
}

section.hero .container{
  padding-bottom: 0px;
  padding-top:70px;
  margin-bottom: -25px;
}

section.hero p.lead, section p.lead{
  font-weight: 600;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1.1rem;
}

section p{
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.left{
  padding-right:25px;
}

.right{
  padding-left:25px;
}

section .container{
  max-width: 1100px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  font-weight: 800;
}

h5{
  margin-top: 40px;
  margin-bottom: 15px;
}

section.lightblue, .lc-block.lightblue, div.lightblue{
  background-color: #ecf5f7;
}

section.grey, .lc-block.grey{
  background-color: #f8f8f8;
}

section.darkpurple, .lc-block.darkpurple, .content-block.darkpurple{
  background-color: #2e0035;
}

.content-block.darkpurple h3{
  font-size: 3rem;
  margin-top: -60px;
}

.lc-block .ratio{
    margin-bottom: 0px;
    margin-top: 40px;
}

section.darkpurple *{
 color:white;}

.icons i{
  padding-bottom: 30px;
}

.icons h4{
  margin-bottom: 20px;
}

.icons.three-col .col-md-4 p{
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.icons.three-col .row{
  margin-left: -35px;
  margin-right: -35px;
}

.icons.three-col .col-md-4{
  padding-left: 35px;
  padding-right: 35px
}

/*HOME*/

.home-hero{
  margin-top: -60px !important;
}

.home-hero h1{
font-size: 3.6rem;
line-height: 1.1;
}

p.lead{
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.related-blog{
  padding: 10px;
}

.post-grid__post-title a {
  color: var(--black);
  text-decoration: none;
}

.blog-hero__metadata {
    margin-top: 0px;
    margin-bottom: 50px;
    align-items: center;
}

.single-blog h2, .single-blog h3{ 
  margin-top: 50px;
  margin-bottom: 20px;
}

a {
  text-decoration: none !important;
  font-weight: 700;
}

.fw-bold{
  font-weight: 800 !important;
}

img.wp-post-image.card-img-top{
  height: 200px;
  width: 200px;
}

.toc-customer {
  position: sticky;
  height: calc(100vh - 4rem);
  top: 7rem;
}

.customer-quote {
  background-color: #E1D5E1;
  border-left: 5px solid var(--bs-primary);
  padding: 1rem;
  margin-left: 50px;
  margin-top: 40px;
  border-radius: 0.25rem;
  padding-right: 25px;
}

.customer-quote p{
  padding-left: 25px;
}

.customer-quote .blockquote-footer {
  margin: auto;
  text-align: right;
}

.customer-quote .blockquote.px-3{
  padding-left: 0px !important;
}

.card-img-top {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.post-title a {
  color: var(--bs-body-color);
  text-decoration: none;
}

.card-body time {
  font-weight: 600;
  color: gray;
}

.card-body h5.post-title{
  margin-top: 0px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.blog-col {
  padding: 10px;
  height: 100%;
}

.post-type-archive-event .blog-col h3{
  margin-bottom: 0px;
}

.speaker-flex{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 25px;
  margin-top: 2px;
}

.speaker-flex p, .speaker-flex h5{
  margin-top: auto;
  margin-bottom: auto;
}

.speaker-flex h5{
  margin-bottom: 5px;
}

.speaker-flex .rounded-circle{
  max-width: 80px;
}

.blockquote-footer{
  margin: auto;
}

h1.title{
  text-transform: capitalize;
}

.share-buttons{
  margin-top: 40px;
  width: 100%;
  align-items: center;
}

.blog-hero__metadata .share-buttons{
  margin-top: 0px;
}

.row.any-data{
  margin-left: -60px;
  margin-right: -60px;
  margin-bottom: -30px !important;
}

.any-data h3 span{
  font-size: calc(100vw/45);
}

.share-buttons svg{
  max-width: 25px;
  width: 25px;
  margin: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.narrow-container{
  max-width: 900px;
  margin: 0 auto;
}

.blog-hero__date{
  float: left;
  margin-right: 20px;
  margin-top: 4px;
  font-weight: 600;
  color: var(--bs-body-color);
}

.blog-hero__date h6{
  margin-bottom: auto;
}

.share-icons{
  justify-content: left;
align-items: center;
}

.blog-hero__metadata .share-icons{
  justify-content: right;
  align-items: center;
}

.blog-hero__author-info{
  min-width: 50%;
}

.share-icons h6{
    display: inline-block;
    margin-bottom: -4px;
}

b, strong {
  font-weight: 700;
}

.blog-hero__author-image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  margin-top: 7px;
}

.blog-hero__author-image img {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
}

.flex{
  display: flex;
}

.flex-col{
flex-direction: column;
display: flex;
}

.align-items-stretch {
  align-items: stretch !important;
  display: flex !important;
}

.bg-lightblue {
	background-color:#ecf5f7;
}

.img-center{
  display: flex;
  align-items: center;
}

.bullet{
  font-size:23px; 
  margin-top:5px
}

i.grey{
  color: #ababab;
}

.lc-block.logo{
  height: 100px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  margin-bottom: 25px;
}

.lc-block.logo .img-fluid{
  align-items: center;
  display: flex;
  mix-blend-mode: multiply;
  max-height: 100px;
  max-width: 200px;
}

.img-fluid{
  mix-blend-mode: multiply;
}

.img-fluid.wp-image-4602{
  mix-blend-mode: normal;
}

.lc-block.img-white-card{
  height: 260px;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: white;
}

.lc-block.img-white-card .img-fluid{
  align-items: center;
    display: flex;
    mix-blend-mode: multiply;
    max-height: 200px;
    width: auto !important;
}

.lc-block.img-blue-card{
  height: 260px;
  padding: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.lc-block.img-blue-card.text-start{
  justify-content: left;
  height: 230px;
}

.lc-block.img-blue-card .img-fluid{
  align-items: center;
    display: flex;
    mix-blend-mode: multiply;
    max-height: 200px;
    width: auto !important;
}

.height-100{
    height:100%;
}

.form-hero__form h3{
  margin-bottom: 20px;
  text-align: left;
  font-size: 22px;
}

.form-hero__form .mktoForm {
    text-align: left;
    margin-top: -20px;
}

.form-hero__form #Demo_Request_Details__c{
    min-height: 100px;
}

.form-hero__copy h2{
  font-size: 30px;
  margin-bottom: 30px;
}

.form-hero__copy h3{
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.form-hero__copy.wysiwyg ul li:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.form-hero__copy.wysiwyg ul li {
  padding: 16px 0 16px 23px;
  position: relative;
  list-style: none;
  line-height: 1.5;
}

.form-hero__copy.wysiwyg ul li::before {
  width: 8px;
  height: 8px;
  top: 26px;
  left: 0;
  position: absolute;
  content: "";
  background-color: var(--light-blue);
  transform: rotate(45deg);
}

.form-hero__copy.wysiwyg ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mktoForm_1007 .mktoButtonRow{
  padding-top: 30px !important;
}

.mktoForm.mktoLayoutAbove .mktoLabel#LblprivacyConsent {
  text-align: left;
  min-width: 107px;
  font-family: 'Montserrat';
  padding-top: 10px;
}

input[type="checkbox"]+label#LblprivacyConsent::before{
  margin-top: -24px;
  position: absolute;
  left: 100px;
}

.mktoForm.mktoLayoutAbove .mktoField, .mktoForm.mktoLayoutAbove .mktoLogicalField{
  min-width: 100% !important;
}

.mktoForm .mktoFieldWrap {
  float: left;
  width: 100% !important;
  // display: flex;
}

.mktoButtonWrap.mktoSimple{
  margin-left: 0px !important;
}

section.content-block{
  background-color: #f8f9fa;
}

section.content-block h2, section.content-block h3, section .container h2, section .container h3{
margin-bottom: 25px;
}

section.content-block h3{
  font-size: 22px;
}

section.content-block blockquote{
  background-color: #ebf5f7;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.centered-cta__wrap {
  background-color: #582f6b;
  text-align: center;
  padding: 74px 109px;
  border-radius: 4px;
  z-index: 2;
}

.centered-cta__heading {
  color: var(--white);
  margin-bottom: 31px;
  font-size: 2.5rem;
  line-height: 1.35;
  font-weight: 800;
}

.centered-cta__copy {
  color: var(--white);
}

.button--white {
  color: #582f6b;
  background-color: var(--white);
  padding: 12px 40px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  position: relative;
  margin-top: 15px;
  display: inline-block;
  line-height: 1.75;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.testimonial__inner {
  background: #ecf5f7;
  border-radius: 4px;
  padding: 50px 60px 50px 60px;
}

h2.testimonial__text{
  font-size: 30px;
}

.logos__images {
  padding-top: 45px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  row-gap: 65px;
  -moz-column-gap: calc((100% - 564px) / 3);
  column-gap: calc((100% - 564px) / 3);
  justify-content: center;
}

section.logos{
    text-align: center;
    background-color: #f8f9fa;
}

.object-fit-cover{
  object-fit: cover;
}

.img-2{
  height:250px;
}

.img-3{
  height:250px;
}

.search-modal .modal-dialog {
  width: 100%;
  padding: 50px;
  max-width: 900px;
  transform: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-modal .modal-header{
  border-bottom: 0px;
  height: 0px;
  margin-top: -100px;
}

.search-modal .modal-content{
  background-color: transparent;
  border: 0px;
}

.search-modal form label{
  width: 100%;
}

.search-modal form label input{
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 2px solid white;
  height: 50px;
  color: white;
  font-size: 40px;
  padding: 0px;
}

.search-modal form label input::placeholder{
  color:white;
  font-size:40px;
}

input:focus, textarea:focus, select:focus{
  outline: none !important;
}

.modal-backdrop {
  background-color: black !important;
  opacity: 0.8 !important;
}

.search-modal input.search-submit{
  display:none !important;
}

.search-modal .search-submit:after{
  content:"GO";
}
    
.col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

.btn-outline-secondary.picostrap-read-more-link{
  padding: 0px;
  border: 0px;
  font-size: 16px;
  color: #592f6b;
  margin-top: 0px;
}

.glossary-box{
  border-bottom: 1px solid gainsboro;
  padding-bottom: 30px;
  margin-bottom: 50px !important;
}

.row.loop-row{
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 !important;
}

.loop-row h5{
  font-weight: 100;
}

.loop-row a{ 
  color: inherit;
}

img.partner-logo-bw{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  mix-blend-mode: multiply;
}

.logo-box{
height: 200px;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
}

a .logo-box:hover{
  background-color: #eaeaeaad;
  transition: background-color 0.15s ease-in-out
}

.logo-box{
  background-color: #f8f8f8;
  transition: background-color 0.15s ease-in-out
}

.row.loop-row.partner-shorcode{
  padding-left: 0px;
  padding-right: 0px;
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.partner-shorcode .arrow{
    width: 100%;
    text-align: right;
    padding-right: 15px;
    font-size: 25px;
}

.grey .logo-box{
  background-color: white;
  transition: background-color 0.15s ease-in-out
}

.logo-box img.partner-logo-bw {
  -webkit-filter: none;
  filter: none;
  max-width: 180px;
  max-height: 50px;
}

.partner-shorcode .col{
  padding:10px;
}

iframe.iframe-responsive{
  height:650px;
}

.box-shadow{
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 19%);
  -webkit-box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 19%);
}

.highlight pre, pre{
    color: #000000 !important;
    background-color: #f0f0f0 !important;
    padding: 10px;
    padding-top: 20px;
    border-left: 3px solid #592f6b;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
}

@media all and (max-width: 993px) {
  iframe.iframe-responsive{
    height:960px !important;
  }
}

@media (min-width: 768px) {
  .col-sm-5ths {
      width: 20%;
      float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
      width: 20%;
      float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
      width: 20%;
      float: left;
  }
}

.resources-template-default  #main-content{
    padding-bottom: 80px;
}

.resource-sidebar h3{
  font-weight: 800;
  font-size:25px;
}

.card-footer p{
  margin-bottom: 0px;;
}

.lc-block .mktoForm{
  max-width: 100%;
}

/*PRICING TABLE STYLES*/

p.table-mob{
  display: none;
}

// table {
//   border: 0;
//   border-collapse: collapse;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   table-layout: fixed;
// }
// table caption {
//   text-align: left;
//   font-size: 1.3em;
//   margin: .5em 0 .75em;
// }
// table thead {
//   display: none;
// }
// table tr {
//   display: block;
//   border: 1px solid #eee;
//   padding: 1em 1em .5em;
// }
// table tr + tr {
//   margin-top: .625em;
// }
// table td {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   border-bottom: 1px solid #eee;
//   line-height: 1.35em;
//   vertical-align: middle;
// }

.blog-template-default  table th, .blog-template-default  table td{
  padding:20px;
}

table td:before {
  content: attr(data-label);
  font-size: .90em;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  max-width: 45%;
  color: #545454;
}
table td + td {
  margin-top: .8em;
}
table td:last-child {
  border-bottom: 0;
}

.mktoForm .mktoLabel#privacyConsent, .mktoForm .mktoLabel#LblAddress{
  display: block !important;
}

.right-img.partner-img{
  padding-left: 15px;
}

.left-img.partner-img{
  padding-right: 10px;
  border-right: 2px solid gainsboro;
}


@media screen and (min-width: 600px) {
  table caption {
    font-size: 1.5em;
  }
  table thead {
    display: table-header-group;
  }
  table tr {
    display: table-row;
    border: 0;
  }
  table th, table td {
    text-align: left;
  }
  table th {
    font-size: .85em;
    text-transform: uppercase;
  }
  table td {
    display: table-cell;
    justify-content: center;
  }
  table td:before {
    display: none;
  }
  table td:last-child {
    border-bottom: 1px solid #eee;
  }

}

/*MEDIA QUERIES*/

@media all and (max-width: 1000px) {
  .any-data h3 span{
    font-size: 20px;
  }

  .img-fluid.mobile-none, video.mobile-none{
    display: none !important;
  }

  section .container {
    padding-top: 30px;
    padding-bottom: 30px;;
  }
}

@media all and (max-width: 991px) {
  .home-hero {
    margin-top: -90px !important;
  }
}

@media all and (max-width: 767px) {

  html{
    overflow-x: hidden !important;
  }

  .blog-col{
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar .container{
    overflow-y: auto;
  }

  .single-blog section.hero .container {
      padding-top: 20px;
  }

  .blog-hero__author-info{
    justify-content: center;
  }

  .row {
    padding-left: 30px;
    padding-right: 30px;
  }

  .img-fluid.wp-image-4156, .img-fluid.wp-image-3950, .img-fluid.wp-image-3533{
    margin-bottom: 0px;
  }

  .img-fluid.wp-image-3818{
    margin-top: 0px;
  }

  .row.any-data{
    margin-bottom: 0px !important;
  }

  .col.py-3 .img-fluid{
    margin-bottom: 0px;
  }

  .single-resources section.hero .container{
    padding-top:0px;
  }

  table{
    width: 100%;
  }

  .mlops-hero{
    padding-bottom: 0px !important;
  }

  .mlops-stack {
    padding-bottom: 0px !important;
  }

  p.lead{
    padding-left: 20px;
    padding-right: 30px;
    font-size: 1rem;
  }

  section .home-hero.row p.lead{
    padding-left: 0px;
    padding-right: 0px;
  }

  #lc-footer .container{
    margin:0px;
  }

  .navbar-collapse.show{
    height:100vh;
  }

  .navbar-light .navbar-nav .nav-link, ul.dropdown-menu li.nav-item a.dropdown-item {
    color: rgba(0, 0, 0, 0.55);
    font-size: 20px;
    padding: 20px;
    padding-bottom: 5px;
  }

  .fa-solid.fa-magnifying-glass{
    font-size: 25px;
    padding: 20px;
    display: inherit !important;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .blog-hero__metadata{
    align-items: center;
    flex-direction: column;
  }

  .blog-hero__metadata .share-icons {
    justify-content: center;
    margin-top: 30px;
  }

  .row .col-md-3:last-child, .row .col-sm-12:last-child, .row .col-md-6:last-child{
    margin-bottom: 0px;
  }

  section.hero h1{
    font-size: 2.3em;
  }

  .btn.demo{
    font-size: 20px;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
    /*margin-bottom: 50px;*/
  }

  .lc-block.logo .img-fluid{
    margin-bottom: unset;
  }

  .wp-image-3818{
    margin-top:50px;
    margin-bottom: 0px;;
  }

  .right {
    padding: 0px;
    padding-left: 0px;
  }

  .left{
    padding: 0px;
    padding-right:0px
  }

  ul.dropdown-menu{
    border:0px;
  }

  ul.dropdown-menu li.nav-item{
    padding:0px;
  }

  ul.dropdown-menu li.nav-item a.dropdown-item{
    text-align: center;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.55);
  }

  ul.dropdown-menu li.nav-item a.dropdown-item:hover, 
  ul.dropdown-menu li.nav-item a.dropdown-item:active, 
  ul.dropdown-menu li.nav-item a.dropdown-item:focus, 
  .dropdown-item.active, 
  .dropdown-item:active{
    background-color: transparent;
  }

  .col-md-4, .col-md-3, .col-md-6{
    margin-bottom:50px;
  } 
}


// @media (max-width: 600px){
//   p.table-mob{
//     display: block;
//     float: left;
//     margin-bottom: 0px;
//     font-weight: 600;
//     margin-left: 10px;
//   }

//   td, td.text-center{
//     justify-content: left !important;
//     text-align: left !important;
//     align-items: center !important;
//   }

//   .table-hover > tbody > tr:hover > *{
//     --bs-table-accent-bt: transparent;
//     --bs-table-hover-bg: transparent;
//    }   

//    tr:hover{
//     --bs-table-accent-bt: transparent;
//     background-color: transparent;
//    }

//   span.purple{
//     font-weight: 700;
//     color: #4b285b;
//   }
// }
    
@media (max-width: 576px){
  .container, .container-sm {
      max-width: 100%;
      padding-left: 25px;
      padding-right: 25px;
  }

  .row, section .home-hero.row{
    padding-left: 25px;
    padding-right: 25px;
  }

  section .row {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cookie-banner{
  display:none;
  background-color: #582f6b;
  color: white;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 20px;
}

.cookie-banner button{
  float:right;
}

.cookie-banner p{
  margin-bottom: 0px;
}


