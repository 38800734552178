// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from /sass/bootstrap5/_variables.scss, paste it here and edit the value.
// Remember that to customize base color values and main variables, you can just launch the WordPress Customizer.

// SOME EXPERIMENTS for your quick testing:
//$primary:teal;
//$body-bg: darkgrey;

@use "sass:math";

//SOME CUSTOMIZATION FOR PICOSTRAP & LC

//CUSTOM SPACING UP TO 8
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: ($spacer * 4.5),  //added
  7: ($spacer * 6), //added
  8: ($spacer * 7.5), //added

) !default;

//ENABLE NEGATIVE MARGIN UTILITY CLASSES
$enable-negative-margins: true;

//CUSTOMIZE UTILITIES VIA UTILITIES API
$utilities: (
  "min-viewport-height": ( //ADD min-vh CLASSES
    property: min-height,
    class: min-vh,
    responsive: true,
    values: ( 
      25: 25vh,
      50: 50vh,
      75: 75vh,
      100: 100vh,
    )
  ),
   
);
